import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { alert } from './alert.reducer';
import { navigation } from './navigation.reducer';
import { costFactors } from './costfactors.reducer';
import { salesEmployees } from './salesemployees.reducer';
import { callCenter }from './callcenter.reducer';
import { address } from './address.reducer';
import { mailseries } from './mailseries.reducer';
import { admin } from './admin.reducer';

const rootReducer = combineReducers({
  authentication,
  admin,
  alert,
  navigation,
  costFactors,
  salesEmployees,
  callCenter,
  address,
  mailseries
});

export default rootReducer;