import { navigationConstants } from "../_constants";

export function navigation(state={items:[]}, action) {

  const items = [];

  switch(action.type){
    case navigationConstants.UPDATE_NAVIGATION_ITEMS:
      if(action.allowedModules.includes('SALES_EMPLOYEES') || action.allowedModules.includes('CALL_CENTER') || action.allowedModules.includes('COST_FACTORS')) {
        items.push({
          title: true,
          name: 'Stammdaten',
          wrapper: {            // optional wrapper object
            element: '',        // required valid HTML5 element tag
            attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
          },
          class: ''             // optional class names space delimited list for title item ex: "text-center"
        });
      }

      if (action.allowedModules.includes('SALES_EMPLOYEES')) {
        items.push( {
          name: 'Verkäufer',
          url: '/masterdata/salesemployees',
          icon: 'fa fa-cart-arrow-down',
        });
      }

      if (action.allowedModules.includes('CALL_CENTER')) {
        items.push( {
          name: 'Callcenter Mitarbeiter',
          url: '/masterdata/callcenter',
          icon: 'fa fa-cart-arrow-down',
        });
      }

      if (action.allowedModules.includes('COST_FACTORS')) {
        items.push( {
          name: 'Kostenfaktoren',
          url: '/masterdata/costfactors',
          icon: 'fa fa-cart-arrow-down',
        });
      }

      if(action.allowedModules.includes('SALES_COST_REPORT') || action.allowedModules.includes('PROVISIONS_REPORT') || action.allowedModules.includes('SALES_YEARLY_REPORT')) {
        items.push({
          title: true,
          name: 'Auswertungen',
          wrapper: {            // optional wrapper object
            element: '',        // required valid HTML5 element tag
            attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
          },
          class: ''             // optional class names space delimited list for title item ex: "text-center"
        });
      }

      if (action.allowedModules.includes('SALES_COST_REPORT')) {
        items.push( {
          name: 'Kostenanalyse Verkäufer',
          url: '/reports/salescosts',
          icon: 'fa fa-cart-arrow-down',
        });
      }

      if (action.allowedModules.includes('PROVISIONS_REPORT')) {
        items.push( {
          name: 'Provisionen',
          url: '/reports/provisions',
          icon: 'fa fa-cart-arrow-down',
        });
      }

      if (action.allowedModules.includes('SALES_YEARLY_REPORT')) {
        items.push( {
          name: 'Jahresstatistiken Verkäufer',
          url: '/reports/salesyearly',
          icon: 'fa fa-cart-arrow-down',
        });
      }

      if (action.allowedModules.includes('DAILY_SALES_PLAN') || action.allowedModules.includes('MAIL_SERIES') || action.allowedModules.includes('MAIL_SERIES_AQUA')) {
        items.push( {
          title: true,
          name: 'Tagespläne',
          wrapper: {            // optional wrapper object
            element: '',        // required valid HTML5 element tag
            attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
          },
          class: ''             // optional class names space delimited list for title item ex: "text-center"
        });

        if (action.allowedModules.includes('DAILY_SALES_PLAN')) {
          items.push({
            name: 'Tagesplan Verkäufer',
            url: '/reports/dailysales',
            icon: 'fa fa-cart-arrow-down',
          });
        }

        if (action.allowedModules.includes('MAIL_SERIES')) {
          items.push( {
            name: 'Serienbrief',
            url: '/mailseries',
            icon: 'fa fa-cart-arrow-down',
          });
        }

        if (action.allowedModules.includes('MAIL_SERIES')) {
          items.push( {
            name: 'Serienbrief Aqua',
            url: '/mailseriesaqua',
            icon: 'fa fa-cart-arrow-down',
          });
        }
      }

      if (action.allowedModules.includes('ADDRESSES_IMPORT') || action.allowedModules.includes('ADDRESSES_DATABASE') || action.allowedModules.includes('ADDRESSES_DOWNLOAD')) {
        items.push({
          title: true,
          name: 'Adressen',
          wrapper: {            // optional wrapper object
            element: '',        // required valid HTML5 element tag
            attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
          },
          class: ''             // optional class names space delimited list for title item ex: "text-center"
        });
      }

      if (action.allowedModules.includes('ADDRESSES_IMPORT')) {
        items.push({
          name: 'Adressen Import',
          url: '/addresses/import',
          icon: 'fa fa-upload',
        });
      }

      if (action.allowedModules.includes('ADDRESSES_DATABASE')) {
        items.push({
          name: 'Adressen Datenbank',
          url: '/addresses/database',
          icon: 'fa fa-database',
        });
      }

      if (action.allowedModules.includes('ADDRESSES_DOWNLOAD')) {
        items.push( {
          name: 'Adressen Download',
          url: '/addresses/download',
          icon: 'fa fa-download',
        });
      }

      return {items : items};
  }

  // DEFAULT
  return { ...state };
}
