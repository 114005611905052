import { addressConstants } from '../_constants';
import {mailseriesConstants} from "../_constants/mailseries.constants";


export function mailseries(state = {}, action) {
  switch (action.type) {

    case mailseriesConstants.START_MAIL_SERIES_EXPORT_REQUEST:
      return {
        ...state,
        exportOverviewModal: true,
        loading: true,
        errorMessage : null,
      };

    case mailseriesConstants.START_MAIL_SERIES_EXPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        exportFiles: action.result.files
      };

    case mailseriesConstants.START_MAIL_SERIES_EXPORT_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        loading: false
      };

    case mailseriesConstants.TOGGLE_EXPORT_OVERVIEW_MODAL:
      return {
        ...state,
        exportOverviewModal: !state.exportOverviewModal,
      }

    case mailseriesConstants.CLEAR_PROPERTIES:
      return {
      }

    default:
      return state
  }
}