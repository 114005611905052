import { authHeader } from '../_helpers';
import "isomorphic-fetch";
import { handleResponse } from './responseHandler';

export const callcenterService = {
    getAllEmployees,
    updateCallCenterEmployees,
    getAllCallCenterItems,
    updateCallCenterItems,
};


function getAllEmployees() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/masterdata/callcenter/employees', requestOptions).then(handleResponse);
}

function updateCallCenterEmployees(employees) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(employees)
    };

    return fetch(process.env.REACT_APP_API_BASE_URL+'/masterdata/callcenter/employees', requestOptions).then(handleResponse);
}

function getAllCallCenterItems() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.REACT_APP_API_BASE_URL+'/masterdata/callcenter', requestOptions).then(handleResponse);
}

function updateCallCenterItems(callCenter) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(callCenter)
    };

    return fetch(process.env.REACT_APP_API_BASE_URL+'/masterdata/callcenter', requestOptions).then(handleResponse);
}
