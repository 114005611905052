export const addressConstants = {
    
    IMPORT_ADDRESS_FILE_REQUEST : 'IMPORT_ADDRESS_FILE_REQUEST',
    IMPORT_ADDRESS_FILE_SUCCESS : 'IMPORT_ADDRESS_FILE_SUCCESS',
    IMPORT_ADDRESS_FILE_FAILURE : 'IMPORT_ADDRESS_FILE_FAILURE',

    CLEAR_ERROR_MESSAGES : 'CLEAR_ERROR_MESSAGES',

    CREATE_ONE_TIME_LINK_REQUEST : 'CREATE_ONE_TIME_LINK_REQUEST',
    CREATE_ONE_TIME_LINK_SUCCESS: 'CREATE_ONE_TIME_LINK_SUCCESS',
    CREATE_ONE_TIME_LINK_FAILURE : 'CREATE_ONE_TIME_LINK_FAILURE',

    GET_ALL_ADDRESSES_REQUEST : 'GET_ALL_ADDRESSES_REQUEST',
    GET_ALL_ADDRESSES_SUCCESS : 'GET_ALL_ADDRESSES_SUCCESS',
    GET_ALL_ADDRESSES_FAILURE : 'GET_ALL_ADDRESSES_FAILURE',

    UPDATE_ADDRESS_REQUEST : 'UPDATE_ADDRESS_REQUEST',
    UPDATE_ADDRESS_SUCCESS : 'UPDATE_ADDRESS_SUCCESS',
    UPDATE_ADDRESS_FAILURE : 'UPDATE_ADDRESS_FAILURE',

    TOGGLE_DELIVERY_INFORMATION : 'TOGGLE_DELIVERY_INFORMATION',

};
