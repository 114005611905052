import { adminConstants } from '../_constants';


export function admin(state = {}, action) {
    switch (action.type) {

        case adminConstants.GET_USERS_REQUEST:
            return {
                ...state,
                loading: true,
                errorMessage: null
            };

        case adminConstants.GET_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.result
            }

        case adminConstants.GET_USERS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: action.error
            }

        case adminConstants.GET_ALLOWED_MODULES_REQUEST:
            return {
                ...state,
                loading: true,
                errorMessage: null
            };

        case adminConstants.GET_ALLOWED_MODULES_SUCCESS:
            return {
                ...state,
                loading: false,
                allowedModules: action.result
            }

        case adminConstants.GET_ALLOWED_MODULES_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: action.error
            }

        case adminConstants.UPDATE_USER_ALLOWED_MODULES_REQUEST:
            return {
                ...state,
                loading: true,
                errorMessage: null
            }


        case adminConstants.UPDATE_USER_ALLOWED_MODULES_SUCCESS:
            let users = state.users;
            let i = users.findIndex((user) => {
                return user.mail === action.result.mail;
            });

            if (i > -1) {
                users[i] = action.result;
            }
            return {
                ...state,
                loading: false,
                users: users,
                selectedUser: null,
                allowedModulesModal: false
            }

        case adminConstants.UPDATE_USER_ALLOWED_MODULES_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: action.error
            }

        case adminConstants.TOGGLE_ALLOWED_MODULES_MODAL:
            return {
                ...state,
                allowedModulesModal: !state.allowedModulesModal,
                selectedUser: action.user,
                errorMessage: null
            }

        default:
            return state
    }
}