import { costfactorsConstants } from '../_constants';


export function costFactors(state = {}, action) {
  switch (action.type) {
    
            
  case costfactorsConstants.GETCOSTFACTORS_REQUEST:
  return {
    loading: true
  };
  case costfactorsConstants.GETCOSTFACTORS_SUCCESS:
  return {
    items: action.costFactors,
    loading: false
  };
  case costfactorsConstants.GETCOSTFACTORS_FAILURE:
  return {
    error: action.error,
    loading: false
  };

  case costfactorsConstants.ADDCOSTFACTORS_REQUEST:
  return {
    ...state,
    loading: true
  };
  case costfactorsConstants.ADDCOSTFACTORS_SUCCESS:
  return {
    ...state,
    loading: false,
    newIntegrationModal : false
  };
  case costfactorsConstants.ADDCOSTFACTORS_FAILURE:
  return {
    ...state,
    error: action.error,
    loading: false
  };

  case costfactorsConstants.EDITCOSTFACTORS_REQUEST:
  return {
    ...state,
    loading: true
  };
  case costfactorsConstants.EDITCOSTFACTORS_SUCCESS:
  return {
    ...state,
    loading: false,
    editCostfactorModal : false
  };
  case costfactorsConstants.EDITCOSTFACTORS_FAILURE:
  return {
    ...state,
    error: action.error,
    loading: false
  };


  case costfactorsConstants.TOGGLE_NEW_MODAL:
  return {
    ...state,
    newIntegrationModal : !state.newIntegrationModal
  };

  case costfactorsConstants.TOGGLE_EDIT_MODAL:
  return {
    ...state,
    editCostfactorModal : !state.editCostfactorModal
  };


      default:
      return state
  }
}