export const callcenterConstants = {
    
    GET_CALLCENTEREMPLOYEES_REQUEST : 'GET_CALLCENTEREMPLOYEES_REQUEST',
    GET_CALLCENTEREMPLOYEES_SUCCESS : 'GET_CALLCENTEREMPLOYEES_SUCCESS',
    GET_CALLCENTEREMPLOYEES_FAILURE : 'GET_CALLCENTEREMPLOYEES_FAILURE',

    UPDATE_CALLCENTEREMPLOYEES_REQUEST : 'UPDATE_CALLCENTEREMPLOYEES_REQUEST',
    UPDATE_CALLCENTEREMPLOYEES_SUCCESS : 'UPDATE_CALLCENTEREMPLOYEES_SUCCESS',
    UPDATE_CALLCENTEREMPLOYEES_FAILURE : 'UPDATE_CALLCENTEREMPLOYEES_FAILURE',

    TOGGLE_CALLCENTER_MODAL : 'TOGGLE_CALLCENTER_MODAL',

    GET_CALLCENTERITEMS_REQUEST : 'GET_CALLCENTERITEMS_REQUEST',
    GET_CALLCENTERITEMS_SUCCESS : 'GET_CALLCENTERITEMS_SUCCESS',
    GET_CALLCENTERITEMS_FAILURE : 'GET_CALLCENTERITEMS_FAILURE',

    ADD_CALLCENTERITEMS_REQUEST : 'ADDCALLCENTEREMPLOYEES_REQUEST',
    ADD_CALLCENTERITEMS_SUCCESS : 'ADDCALLCENTEREMPLOYEES_SUCCESS',
    ADD_CALLCENTERITEMS_FAILURE : 'ADD_CALLCENTERITEM_FAILURE',

    UPDATE_CALLCENTERITEMS_REQUEST : 'UPDATE_CALLCENTERITEMS_REQUEST',
    UPDATE_CALLCENTERITEMS_SUCCESS : 'UPDATE_CALLCENTERITEMS_SUCCESS',
    UPDATE_CALLCENTERITEMS_FAILURE : 'UPDATE_CALLCENTERITEMS_FAILURE',

    REMOVE_CALLCENTERITEMS_REQUEST : 'REMOVE_CALLCENTERITEMS_REQUEST',
    REMOVE_CALLCENTERITEMS_SUCCESS : 'REMOVE_CALLCENTERITEMS_SUCCESS',
    REMOVE_CALLCENTERITEMS_FAILURE : 'REMOVE_CALLCENTERITEMS_FAILURE',

    CLEAR_ERROR_MESSAGES : 'CLEAR_ERROR_MESSAGES'
};
