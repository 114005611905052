import { authHeader } from '../_helpers';
import "isomorphic-fetch";
import { history } from '../_helpers';
import { handleResponse } from './responseHandler';

export const addressService = {
    importAddressFile,
    createOneTimeLink,
    getAllAddresses,
    updateAddress
};

function importAddressFile(data) {
    let user = JSON.parse(localStorage.getItem('jtcareuser'));

    let header = {
      'Authorization': 'Bearer ' + user.access_token,
    };

    const requestOptions = {
      method: 'POST',
      headers: header,
      body: data
    };

    return fetch(process.env.REACT_APP_API_BASE_URL+'/addresses/fileupload', requestOptions).then(handleResponse);
}

function createOneTimeLink() {
  const requestOptions = {
    method: 'POST',
    headers: authHeader()
  };

  return fetch(process.env.REACT_APP_API_BASE_URL+'/addresses/preparedownload', requestOptions).then(handleResponse);
}

function getAllAddresses(filter, page, limit) {
    let requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    if (filter) {
        requestOptions = {
            ...requestOptions,
            body: JSON.stringify(filter)
        };
    }


    return fetch(process.env.REACT_APP_API_BASE_URL + '/addresses/fetch/' + page + '/' + limit, requestOptions).then(handleResponse);
}

function updateAddress(address) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(address)
    };
    return fetch(process.env.REACT_APP_API_BASE_URL + '/addresses/update', requestOptions).then(handleResponse);
}
