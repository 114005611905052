import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../_actions';
import { history } from '../_helpers';

class Logout extends React.Component {
    
    constructor(props) {
        super(props);

        // reset login status
        this.props.dispatch(userActions.logout());
    }

    componentDidMount(){
        console.log('login')
        history.push('/login');
    }
     
    render() {
    
    return (
        <div className="app flex-row align-items-center">
        </div>
    );
    }
}
    
function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    return {
        loggingIn
    };
}

const connectedLogoutPage = connect(mapStateToProps)(Logout);
export { connectedLogoutPage as Logout }; 