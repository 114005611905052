import { addressConstants } from '../_constants';


export function address(state = {}, action) {
  switch (action.type) {

  case addressConstants.IMPORT_ADDRESS_FILE_REQUEST:
  return {
    loading: true,
    errorMessages : []
  };

  case addressConstants.IMPORT_ADDRESS_FILE_SUCCESS:
  return {
    oneTimeToken: action.importResult.token,
    loading: false
  };

  case addressConstants.IMPORT_ADDRESS_FILE_FAILURE:
  return {
    errorMessages: action.errorMessages,
    loading: false
  };

  case addressConstants.CREATE_ONE_TIME_LINK_REQUEST:
  return {
    loading: true,
    errorMessages : [],
    oneTimeToken : null
  };

  case addressConstants.CREATE_ONE_TIME_LINK_SUCCESS:
  return {
    oneTimeToken: action.tokenResult.token,
    loading: false
  };

  case addressConstants.CREATE_ONE_TIME_LINK_FAILURE:
  return {
    errorMessages: action.errorMessages,
    loading: false
  };

    case addressConstants.GET_ALL_ADDRESSES_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: null
      };

    case addressConstants.GET_ALL_ADDRESSES_SUCCESS:
      return {
        ...state,
        loading: false,
        addresses: action.result.response,
        previousPage: action.result.previousPage,
        nextPage: action.result.nextPage,
        currentPage: action.result.currentPage,
        lastPage: action.result.lastPage,
      };

    case addressConstants.GET_ALL_ADDRESSES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage
      };

    case addressConstants.UPDATE_ADDRESS_REQUEST:
      return {
        ...state,
        updateLoading: true,
        errorMessage: null
      };

    case addressConstants.UPDATE_ADDRESS_SUCCESS:
      let addresses = state.addresses;
      let updatedAddress = action.result;
      let i = addresses.findIndex((item) => {
        return item.externalUUID === updatedAddress.externalUUID;
      });

      if (i < 0) {
        addresses.push(updatedAddress);
      } else {
        addresses[i] = updatedAddress;
      }

      return {
        ...state,
        updateLoading: false,
        addresses: addresses
      };

    case addressConstants.UPDATE_ADDRESS_FAILURE:
      return {
        ...state,
        updateLoading: false,
        errorMessage: action.errorMessage,
      };

    case addressConstants.CLEAR_ERROR_MESSAGES:
      return {
        ...state,
        loading: false,
        errorMessages: [],
        reportMessage : null,
        fileName : null,
        oneTimeToken : null
      };

    case addressConstants.TOGGLE_DELIVERY_INFORMATION:
      return {
        ...state,
        deliveryInformation: !state.deliveryInformation
      };

      default:
      return state
  }
}