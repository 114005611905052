import { salesEmployeesConstants } from '../_constants';


export function salesEmployees(state = {}, action) {
  switch (action.type) {
    
            
  case salesEmployeesConstants.GETSALESEMPLOYEES_REQUEST:
  return {
    loading: true,
    items : []
  };
  case salesEmployeesConstants.GETSALESEMPLOYEES_SUCCESS:
  return {
    items: action.salesEmployees,
    loading: false
  };
  case salesEmployeesConstants.GETSALESEMPLOYEES_FAILURE:
  return {
    error: action.error,
    items : [],
    loading: false
  };

  case salesEmployeesConstants.UPDATESALESEMPLOYEES_REQUEST:
  return {
    loading: true
  };
  case salesEmployeesConstants.UPDATESALESEMPLOYEES_SUCCESS:
  return {
    items: action.salesEmployees,
    loading: false
  };
  case salesEmployeesConstants.UPDATESALESEMPLOYEES_FAILURE:
  return {
    error: action.error,
    loading: false
  };

  case salesEmployeesConstants.TOGGLE_SALARY_MODAL:
  return {
    ...state,
    salaryModal : !state.salaryModal,
    salaryEmployee : action.employee,
    salaryItems : action.salaryItems
  };


  case salesEmployeesConstants.UPDATESALARYITEMS_REQUEST:{
    return {
      ...state,
      loading: true
    }
  }

  case salesEmployeesConstants.UPDATESALARYITEMS_SUCCESS:{
    return {
      ...state,
      salaryItems : [] ,
      salaryEmployee : null,
      salaryModal : false,
      loading: false
    }
  }
  case salesEmployeesConstants.UPDATESALARYITEMS_FAILURE:{
    return {
      ...state,
      error: action.error,
      loading: false
    };
  }

  case salesEmployeesConstants.REQUESTCOSTREPORT_REQUEST:{
    return {
      ...state,
      loading: true
    };
  }

  case salesEmployeesConstants.REQUESTCOSTREPORT_SUCCESS :{
    return {
      ...state,
      loading: false,
      errorMessages: action.result.errorMessages,
      reportMessage : action.result.message
    };
  }
  case salesEmployeesConstants.REQUESTCOSTREPORT_FAILURE:{
    return {
      ...state,
      loading: false,
      errorMessages: action.result.errorMessages,
      reportMessage : action.result.message
    };
  }
  
  case salesEmployeesConstants.REQUESTPROVISIONSREPORT_REQUEST:{
    return {
      ...state,
      loading: true
    };
  }

  case salesEmployeesConstants.REQUESTPROVISIONSREPORT_SUCCESS :{
    return {
      ...state,
      loading: false,
      errorMessages: action.result.errorMessages,
      reportMessage : action.result.message,
      fileName : action.result.fileName
    };
  }
  case salesEmployeesConstants.REQUESTPROVISIONSREPORT_FAILURE:{
    return {
      ...state,
      loading: false,
      errorMessages: action.result.errorMessages,
      reportMessage : action.result.message,
      fileName : null
    };
  }

  case salesEmployeesConstants.REQUEST_YEARLY_REQUEST:{
    return {
      ...state,
      loading: true
    };
  }

  case salesEmployeesConstants.REQUEST_YEARLY_SUCCESS :{
    return {
      ...state,
      loading: false,
      errorMessages: action.result.errorMessages,
      reportMessage : action.result.message,
    };
  }
  case salesEmployeesConstants.REQUEST_YEARLY_FAILURE:{
    return {
      ...state,
      loading: false,
      errorMessages: action.result.errorMessages,
      reportMessage : action.result.message,
    };
  }
  
  case salesEmployeesConstants.CLEAR_ERROR_MESSAGES:{
    return {
      ...state,
      loading: false,
      errorMessages: [],
      reportMessage : null,
      fileName : null
    };
  }

  case salesEmployeesConstants.GENERATE_DAILY_PLAN_REQUEST:{
    return {
      ...state,
      loading: true,
      errorMessages: null,
      reportMessage : null,
    };
  }

  case salesEmployeesConstants.GENERATE_DAILY_PLAN_SUCCESS:{
    return {
      ...state,
      loading: false,
      errorMessages: null,
      reportMessage : 'Report wurde erfolgreich per Mail verschickt',
    };
  }

  case salesEmployeesConstants.GENERATE_DAILY_PLAN_FAILURE:{
    return {
      ...state,
      loading: false,
      errorMessages: action.errorMessages,
      reportMessage : null
    };
  }

    case salesEmployeesConstants.DELETE_SALESEMPLOYEE_REQUEST:
      return {
        ...state,
        subloading: true,
      };

    case salesEmployeesConstants.DELETE_SALESEMPLOYEE_SUCCESS:
      let employees = state.items;
      let index = employees.findIndex((item) => {
        return item.externalUUID == action.employee.externalUUID;
      });
      if (index >= 0) {
        employees.splice(index, 1);
      }
      return {
        ...state,
        subloading: false,
        items: employees,
        deletionModal: false,
        employeeToDelete: null,
        suberror: null
      };

    case salesEmployeesConstants.DELETE_SALESEMPLOYEE_FAILURE:
      return {
        ...state,
        subloading: false,
        suberror: action.messages
      };

    case salesEmployeesConstants.TOGGLE_SALESEMPLOYEE_DELETE_MODAL:
      return {
        ...state,
        deletionModal: !state.deletionModal,
        employeeToDelete: action.employeeToDelete,
        suberror: null
      }

  case 'CLEAR_ALL_MESSAGES':{
    return {
      ...state,
      loading: false,
      errorMessages: [],
      reportMessage : []
    };
  }
  
  
  

      default:
      return state
  }
}