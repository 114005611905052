import { userConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('jtcareuser'));
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {

    case userConstants.GET_USER_ALLOWED_MODULES_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          allowedModules: action.allowedModules
        }
      }
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };

    case userConstants.ADD_ADDITIONAL_USER_DATA:
      return {
        ...state,
        user: action.user
      }

    case userConstants.FETCH_INITIAL_SHOPIFY_DATA_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };

    case userConstants.ACTIVATE_USER_ACCOUNT_REQUEST:
      return {
        ...state,
        loading : true,
        errorMessage : null
      };

    case userConstants.ACTIVATE_USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading : false,
        errorMessage : null
      };

    case userConstants.ACTIVATE_USER_ACCOUNT_FAILURE:
      return {
        ...state,
        loading : false,
        errorMessage : action.error
      };


    case userConstants.LOGIN_FAILURE:
      return {};
    case userConstants.LOGOUT:
      return {};

    case userConstants.CHECK_AUTHENTICATION_REQUEST:
      return {
        ...state
      };

    case userConstants.CHECK_AUTHENTICATION_SUCCESS:
      return {
        ...state
      };

    case userConstants.CHECK_AUTHENTICATION_FAILURE:
      return {
        ...state
      };



    default:
      return state
  }
}