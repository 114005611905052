import React, { Component } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { AppAsideToggler, AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import logo from '../../assets/img/brand/logo.png'
import sygnet from '../../assets/img/brand/logo.png'
import {userActions} from '../../_actions';
import {navigationConstants} from '../../_constants';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {

  componentDidMount() {
    this.props.dispatch(userActions.getUserAllowedModules()).then(() => {
      this.props.dispatch({type: navigationConstants.UPDATE_NAVIGATION_ITEMS, allowedModules: this.props.user.allowedModules});
    });
  }

  render() {

    // eslint-disable-next-line
    const { user } = this.props;
    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        {/*<AppNavbarBrand
          full={{ src: logo, width: 89, height: 25, alt: 'pathway-solutions logo' }}
          minimized={{ src: sygnet, width: 30, height: 30, alt: 'pathway-solutions logo' }}
        />*/}
        <AppSidebarToggler className="d-md-down-none" display="lg" />
 
          <Nav className="ml-auto" navbar>
            <AppHeaderDropdown direction="down">
              <DropdownToggle nav>
                {user.firstName} {user.lastName} &nbsp;
                {user.userProfilePicture &&<img src={'https://www.w3schools.com/howto/img_avatar.png'} className="img-avatar" alt="" />}
              </DropdownToggle>
              <DropdownMenu right style={{ right: 'auto' }}>
                {(user.allowedModules.findIndex(item => item === "ADMIN") > -1) && <DropdownItem href="/admin"><i className="fa fa-lock"></i>Nutzerverwaltung</DropdownItem>}
                <DropdownItem href="/logout"><i className="fa fa-lock"></i>Logout</DropdownItem>
              </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
        {/*<AppAsideToggler className="d-md-down-none" />*/}
        {/*<AppAsideToggler className="d-lg-none" mobile />*/}
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
      user
  };
}

export default connect(mapStateToProps)(DefaultHeader);
