import { authHeader } from '../_helpers';
import "isomorphic-fetch";
import { history } from '../_helpers';
import { handleResponse } from './responseHandler';

export const salesEmployeesService = {
    getAllEmployees,
    updateSalesEmployees,
    deleteSalesEmployee,
    getSalesSalary,
    updateSalaryItems,
    requestCostReport,
    requestProvisionsReport,
    requestYearlyStatistics,
    generateDailyPlan
};


function getAllEmployees() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/masterdata/salesemployees', requestOptions).then(handleResponse);
}

function updateSalesEmployees(employees) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(employees)
    };

    return fetch(process.env.REACT_APP_API_BASE_URL+'/masterdata/salesemployees', requestOptions).then(handleResponse);
}

function deleteSalesEmployee(employee) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(employee)
    };

    return fetch(process.env.REACT_APP_API_BASE_URL+'/masterdata/salesemployees', requestOptions).then(handleResponse);
}

function getSalesSalary(employee) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(employee)
    };

    return fetch(process.env.REACT_APP_API_BASE_URL+'/masterdata/salesemployees/salary', requestOptions).then(handleResponse);
}

function updateSalaryItems(salesEmployee, salaryItems) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({employee:salesEmployee, salaries : salaryItems})
    };

    return fetch(process.env.REACT_APP_API_BASE_URL+'/masterdata/salesemployees/salaryupdate', requestOptions).then(handleResponse);
}

function requestCostReport(month, year, email, employeeIds) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({month, year, email, employeeIds})
    };

    return fetch(process.env.REACT_APP_API_BASE_URL+'/reports/salescosts', requestOptions).then(handleResponse);
}

function requestProvisionsReport(month, year, persistInvoicesParameter,aquaParameter) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({month,year,persistInvoicesParameter,aquaParameter}),
    };

    return fetch(process.env.REACT_APP_API_BASE_URL+'/reports/provisions', requestOptions).then(handleResponse);
}


function requestYearlyStatistics(email) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({email}),
    };

    return fetch(process.env.REACT_APP_API_BASE_URL+'/reports/yearlysales', requestOptions).then(handleResponse);
}

function generateDailyPlan(email, selectedUUIDS) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({email,selectedUUIDS}),
    };

    return fetch(process.env.REACT_APP_API_BASE_URL+'/reports/dailyplan', requestOptions).then(handleResponse);
}

