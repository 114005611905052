import { authHeader } from '../_helpers';
import "isomorphic-fetch";
import { history } from '../_helpers';
import { handleResponse } from './responseHandler';

export const costfactorsService = {
    getAllCostFactors,
    addCostFactors,
    editCostFactor
};


function getAllCostFactors() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/masterdata/costfactors', requestOptions).then(handleResponse);
}

function addCostFactors(configuration) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(configuration)
    };

    return fetch(process.env.REACT_APP_API_BASE_URL+'/masterdata/costfactors', requestOptions).then(handleResponse);
}

function editCostFactor(costfactor) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(costfactor)
    };

    return fetch(process.env.REACT_APP_API_BASE_URL+'/masterdata/costfactors', requestOptions).then(handleResponse);
}
