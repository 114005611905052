export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    REGISTER_SHOPIFY_REQUEST : 'REGISTER_SHOPIFY_REQUEST',
    REGISTER_SHOPIFY_SUCCESS : 'REGISTER_SHOPIFY_SUCCESS',
    REGISTER_SHOPIFY_FAILURE : 'REGISTER_SHOPIFY_FAILURE',

    FETCH_INITIAL_SHOPIFY_DATA_SUCCESS : 'FETCH_INITIAL_SHOPIFY_DATA_SUCCESS',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    GET_USER_ALLOWED_MODULES_REQUEST : 'GET_USER_ALLOWED_MODULES_REQUEST',
    GET_USER_ALLOWED_MODULES_SUCCESS : 'GET_USER_ALLOWED_MODULES_SUCCESS',
    GET_USER_ALLOWED_MODULES_FAILURE : 'GET_USER_ALLOWED_MODULES_FAILURE',

    ADD_ADDITIONAL_USER_DATA : 'ADD_ADDITIONAL_USER_DATA',
    
    CHANGE_REGISTER_MODAL: 'CHANGE_REGISTER_MODAL',
    TOGGLE_DELETE_MODAL: 'TOGGLE_DELETE_MODAL',
    DELETE_SUCCESSFUL:'DELETE_SUCCESSFUL',
    LOGOUT: 'USERS_LOGOUT',

    ACTIVATE_USER_ACCOUNT_REQUEST: 'ACTIVATE_USER_ACCOUNT_REQUEST',
    ACTIVATE_USER_ACCOUNT_SUCCESS: 'ACTIVATE_USER_ACCOUNT_SUCCESS',
    ACTIVATE_USER_ACCOUNT_FAILURE: 'ACTIVATE_USER_ACCOUNT_FAILURE',

    RELOAD_USER_REQUEST : 'RELOAD_USER_REQUEST',
    RELOAD_USER_SUCCESS : 'RELOAD_USER_SUCCESS',
    RELOAD_USER_FAILURE : 'RELOAD_USER_FAILURE',

    CHECK_AUTHENTICATION_REQUEST : 'CHECK_AUTHENTICATION_REQUEST',
    CHECK_AUTHENTICATION_SUCCESS : 'CHECK_AUTHENTICATION_SUCCESS',
    CHECK_AUTHENTICATION_FAILURE : 'CHECK_AUTHENTICATION_FAILURE',

};
