export const salesEmployeesConstants = {
    
    GETSALESEMPLOYEES_REQUEST : 'GETSALESEMPLOYEES_REQUEST',
    GETSALESEMPLOYEES_SUCCESS : 'GETSALESEMPLOYEES_SUCCESS',
    GETSALESEMPLOYEES_FAILURE : 'GETSALESEMPLOYEES_FAILURE',

    UPDATESALESEMPLOYEES_REQUEST : 'UPDATESALESEMPLOYEES_REQUEST',
    UPDATESALESEMPLOYEES_SUCCESS : 'UPDATESALESEMPLOYEES_SUCCESS',
    UPDATESALESEMPLOYEES_FAILURE : 'UPDATESALESEMPLOYEES_FAILURE',

    TOGGLE_SALARY_MODAL : 'TOGGLE_SALARY_MODAL',
    ADD_SALARY_ITEM : 'ADD_SALARY_ITEM',
    UPDATE_SALARY_ITEM : 'UPDATE_SALARY_ITEM',
    DELETE_SALARY_ITEM : 'DELETE_SALARY_ITEM',

    UPDATESALARYITEMS_REQUEST : 'UPDATESALARYITEMS_REQUEST',
    UPDATESALARYITEMS_SUCCESS : 'UPDATESALARYITEMS_SUCCESS',
    UPDATESALARYITEMS_FAILURE : 'UPDATESALARYITEMS_FAILURE',

    REQUESTCOSTREPORT_REQUEST : 'REQUESTCOSTREPORT_REQUEST',
    REQUESTCOSTREPORT_SUCCESS : 'REQUESTCOSTREPORT_SUCCESS',
    REQUESTCOSTREPORT_FAILURE : 'REQUESTCOSTREPORT_FAILURE',

    REQUESTPROVISIONSREPORT_REQUEST : 'REQUESTPROVISIONSREPORT_REQUEST',
    REQUESTPROVISIONSREPORT_SUCCESS : 'REQUESTPROVISIONSREPORT_SUCCESS',
    REQUESTPROVISIONSREPORT_FAILURE : 'REQUESTPROVISIONSREPORT_FAILURE',

    CLEAR_ERROR_MESSAGES : 'CLEAR_ERROR_MESSAGES',

    REQUEST_YEARLY_REQUEST :'REQUEST_YEARLY_REQUEST',
    REQUEST_YEARLY_SUCCESS :'REQUEST_YEARLY_SUCCESS',
    REQUEST_YEARLY_FAILURE :'REQUEST_YEARLY_FAILURE',
    
    GENERATE_DAILY_PLAN_REQUEST : 'GENERATE_DAILY_PLAN_REQUEST',
    GENERATE_DAILY_PLAN_SUCCESS : 'GENERATE_DAILY_PLAN_SUCCESS',
    GENERATE_DAILY_PLAN_FAILURE : 'GENERATE_DAILY_PLAN_FAILURE',


    DELETE_SALESEMPLOYEE_REQUEST : 'DELETE_SALESEMPLOYEE_REQUEST',
    DELETE_SALESEMPLOYEE_SUCCESS : 'DELETE_SALESEMPLOYEE_SUCCESS',
    DELETE_SALESEMPLOYEE_FAILURE : 'DELETE_SALESEMPLOYEE_FAILURE',

    TOGGLE_SALESEMPLOYEE_DELETE_MODAL : 'TOGGLE_SALESEMPLOYEE_DELETE_MODAL',


};
