import React from 'react';
import Loadable from 'react-loadable'

import DefaultLayout from './containers/DefaultLayout';

function Loading() {
  return <div>Loading...</div>;
}

const CostFactors = Loadable({
  loader: () => import('./CostFactors'),
  loading: Loading,
});

const SalesEmployees = Loadable({
  loader: () => import('./SalesEmployees'),
  loading: Loading,
});

const CallCenter = Loadable({
  loader: () => import('./CallCenter'),
  loading: Loading,
});
 
const SalesEmployeesCostReport = Loadable({
  loader: () => import('./SalesEmployeesCostReport'),
  loading: Loading,
});

const SalesStatisticsYearly = Loadable({
  loader: () => import('./SalesStatisticsYearly'),
  loading: Loading,
});

const ProvisionsReport = Loadable({
  loader: () => import('./ProvisionsReport'),
  loading: Loading,
});

const AddressImport = Loadable({
  loader: () => import('./AddressImport'),
  loading: Loading,
});

const AddressDatabase = Loadable({
  loader: () => import('./AddressDatabase'),
  loading: Loading,
});
 
const AddressDownload = Loadable({
  loader: () => import('./Address'),
  loading: Loading,
});

const DailySalesPlan = Loadable({
  loader: () => import('./DailySalesPlan'),
  loading: Loading,
});

const MailSeries = Loadable({
  loader: () => import('./MailSeries'),
  loading: Loading
});

const MailSeriesAqua = Loadable({
  loader: () => import('./MailSeriesAqua'),
  loading: Loading
});

const Admin = Loadable({
  loader: () => import('./Admin'),
  loading: Loading
});

 //https:github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Home', component: DefaultLayout },
  { path: '/masterdata/costfactors', name: 'Kostenfaktoren', component: CostFactors },
  { path: '/masterdata/salesemployees', name: 'Verkäufer', component: SalesEmployees },
  { path: '/masterdata/callcenter', name: 'Callcenter Mitarbeiter', component: CallCenter },
  { path: '/reports/salesyearly', name: 'Jahresstatistik Verkäufer', component: SalesStatisticsYearly },
  { path: '/reports/salescosts', name: 'Kostenanalyse Verkäufer', component: SalesEmployeesCostReport },
  { path: '/reports/provisions', name: 'Provisionsabrechnung', component: ProvisionsReport },
  { path: '/reports/dailysales', name: 'Tagesplan Verkäufer', component: DailySalesPlan },
  { path: '/addresses/import', name: 'Adressen Import', component: AddressImport },
  { path: '/addresses/database', name: 'Adressen Datenbank', component: AddressDatabase },
  { path: '/addresses/download', name: 'Adressen Download', component: AddressDownload },
  { path: '/addresses', name: 'Adressen Datenbank', component: AddressDatabase },
  { path: '/mailseries', name: 'Serienbrief', component: MailSeries },
  { path: '/mailseriesaqua', name: 'Serienbrief AQUA', component: MailSeriesAqua },
  { path: '/admin', name: 'Admin', component: Admin },

];

export default routes;
