import { salesEmployeesConstants } from '../_constants';
import { salesEmployeesService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const salesEmployeesActions = {
    getAllEmployees,
    updateEmployees,
    deleteEmployee,
    toggleDeletionModal,
    toggleSalaryModal,
    updateSalaryItems,
    requestCostReport,
    requestProvisionsReport,
    requestYearlyStatistics,
    generateDailyPlan,
};

function getAllEmployees() {
    return dispatch => {
        dispatch(request());

        return salesEmployeesService.getAllEmployees()
            .then(
                salesEmployees => {
                    dispatch(success(salesEmployees));
                    return salesEmployees
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    return []
                }
            );
    };

    function request() { return { type: salesEmployeesConstants.GETSALESEMPLOYEES_REQUEST } }
    function success(salesEmployees) { return { type: salesEmployeesConstants.GETSALESEMPLOYEES_SUCCESS, salesEmployees } }
    function failure(error) { return { type: salesEmployeesConstants.GETSALESEMPLOYEES_FAILURE, error } }
}


function updateEmployees(salesEmployees) {
    return dispatch => {
        dispatch(request(salesEmployees));

        return salesEmployeesService.updateSalesEmployees(salesEmployees)
            .then(
                salesEmployees => {
                    if (salesEmployees.errorMessages && salesEmployees.errorMessages.length > 0) {
                        dispatch(failure(salesEmployees.errorMessages));
                    } else {
                        dispatch(success(salesEmployees))
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(salesEmployees) { return { type: salesEmployeesConstants.UPDATESALESEMPLOYEES_REQUEST, salesEmployees } }
    function success(salesEmployees) { return { type: salesEmployeesConstants.UPDATESALESEMPLOYEES_SUCCESS, salesEmployees } }
    function failure(errorMessages) { return { type: salesEmployeesConstants.UPDATESALESEMPLOYEES_FAILURE, messages: errorMessages } }
}

function deleteEmployee(employee) {
    return dispatch => {
        dispatch(request(employee));

        return salesEmployeesService.deleteSalesEmployee(employee)
            .then(
                employee => {
                    if (employee == null) {
                        console.log("DELETE FAILURE")
                        dispatch(failure("Es ist ein Fehler beim Löschen aufgetreten"));
                    } else {
                        dispatch(success(employee))
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(employee) { return { type: salesEmployeesConstants.DELETE_SALESEMPLOYEE_REQUEST, employee } }
    function success(employee) { return { type: salesEmployeesConstants.DELETE_SALESEMPLOYEE_SUCCESS, employee } }
    function failure(errorMessages) { return { type: salesEmployeesConstants.DELETE_SALESEMPLOYEE_FAILURE, messages: errorMessages } }
}

function toggleDeletionModal(employee) {
    return dispatch => {
        dispatch({type: salesEmployeesConstants.TOGGLE_SALESEMPLOYEE_DELETE_MODAL, employeeToDelete: employee});
    };
}

function toggleSalaryModal(employee,openModal){
    return dispatch => {
        if(openModal){
            return salesEmployeesService.getSalesSalary(employee)
                .then(
                    salaryItems => {
                            dispatch({type : salesEmployeesConstants.TOGGLE_SALARY_MODAL, employee, salaryItems});
                    },
                    error => {
                        dispatch(alertActions.error(error));
                    }
                );
        }else{
            dispatch({type : salesEmployeesConstants.TOGGLE_SALARY_MODAL});
            return Promise.resolve();
        }
    }
}

function updateSalaryItems(salaryEmployee,salaryItems) {
    return dispatch => {
        dispatch(request());

        return salesEmployeesService.updateSalaryItems(salaryEmployee,salaryItems)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages));
                    } else {
                        dispatch(success())
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: salesEmployeesConstants.UPDATESALARYITEMS_REQUEST } }
    function success(result) { return { type: salesEmployeesConstants.UPDATESALARYITEMS_SUCCESS, result } }
    function failure(errorMessages) { return { type: salesEmployeesConstants.UPDATESALARYITEMS_FAILURE, messages: errorMessages } }
}

function requestCostReport(month,year, email, employeeIds) {
    return dispatch => {
        dispatch(request());

        return salesEmployeesService.requestCostReport(month,year, email, employeeIds)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result));
                    } else {
                        dispatch(success(result))
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: salesEmployeesConstants.REQUESTCOSTREPORT_REQUEST } }
    function success(result) { return { type: salesEmployeesConstants.REQUESTCOSTREPORT_SUCCESS, result } }
    function failure(result) { return { type: salesEmployeesConstants.REQUESTCOSTREPORT_FAILURE, result } }
}

function requestProvisionsReport(month,year, finalize,aquaParameter) {
    return dispatch => {
        dispatch(request());

        return salesEmployeesService.requestProvisionsReport(month,year,finalize,aquaParameter)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages));
                    } else {
                        dispatch(success(result))
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: salesEmployeesConstants.REQUESTPROVISIONSREPORT_REQUEST } }
    function success(result) { return { type: salesEmployeesConstants.REQUESTPROVISIONSREPORT_SUCCESS, result } }
    function failure(errorMessages) { return { type: salesEmployeesConstants.REQUESTPROVISIONSREPORT_FAILURE, errorMessages } }
}


function requestYearlyStatistics(email) {
    return dispatch => {
        dispatch(request());

        return salesEmployeesService.requestYearlyStatistics(email)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages));
                    } else {
                        dispatch(success(result))
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: salesEmployeesConstants.REQUEST_YEARLY_REQUEST } }
    function success(result) { return { type: salesEmployeesConstants.REQUEST_YEARLY_SUCCESS, result } }
    function failure(errorMessages) { return { type: salesEmployeesConstants.REQUEST_YEARLY_FAILURE, errorMessages } }
}

function generateDailyPlan(email, selectedEmployeeUUIDS) {
    return dispatch => {
        dispatch(request());

        return salesEmployeesService.generateDailyPlan(email, selectedEmployeeUUIDS)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages));
                    } else {
                        dispatch(success(result))
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: salesEmployeesConstants.GENERATE_DAILY_PLAN_REQUEST } }
    function success(result) { return { type: salesEmployeesConstants.GENERATE_DAILY_PLAN_SUCCESS, result } }
    function failure(errorMessages) { return { type: salesEmployeesConstants.GENERATE_DAILY_PLAN_FAILURE, errorMessages } }
}