import { costfactorsConstants } from '../_constants';
import { costfactorsService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const costFactorsActions = {
    getAllCostFactors,
    addNewCostFactor,
    editCostFactor,
    toggleNewCostFactorModal,
    toggleEditCostFactorModal
};

function getAllCostFactors() {
    return dispatch => {
        dispatch(request());

        costfactorsService.getAllCostFactors()
            .then(
                costFactors => {
                    dispatch(success(costFactors));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(costFactors) { return { type: costfactorsConstants.GETCOSTFACTORS_REQUEST, costFactors } }
    function success(costFactors) { return { type: costfactorsConstants.GETCOSTFACTORS_SUCCESS, costFactors } }
    function failure(error) { return { type: costfactorsConstants.GETCOSTFACTORS_FAILURE, error } }
}


function addNewCostFactor(costFactors) {
    return dispatch => {
        dispatch(request(costFactors));

        return costfactorsService.addCostFactors(costFactors)
            .then(
                () => {
                        dispatch(success())
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: costfactorsConstants.ADDCOSTFACTORS_REQUEST } }
    function success() { return { type: costfactorsConstants.ADDCOSTFACTORS_SUCCESS } }
    function failure(errorMessages) { return { type: costfactorsConstants.ADDCOSTFACTORS_FAILURE, messages: errorMessages } }
}

function editCostFactor(editCostfactor) {
    return dispatch => {
        dispatch(request());

        return costfactorsService.editCostFactor(editCostfactor)
            .then(
                () => {
                        dispatch(success())
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: costfactorsConstants.EDITCOSTFACTORS_REQUEST } }
    function success() { return { type: costfactorsConstants.EDITCOSTFACTORS_SUCCESS } }
    function failure(errorMessages) { return { type: costfactorsConstants.EDITCOSTFACTORS_FAILURE, messages: errorMessages } }
}

function toggleNewCostFactorModal(costFactors) {
    return dispatch => {
        dispatch({ type: costfactorsConstants.TOGGLE_NEW_MODAL });
    }
}

function toggleEditCostFactorModal() {
    return dispatch => {
        dispatch({ type: costfactorsConstants.TOGGLE_EDIT_MODAL });
    }
}

