import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {App} from './App';
import { Provider } from 'react-redux';
import { store } from './_helpers';

// Disable ServiceWorker
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
unregister();

ReactDOM.render(<Provider store={store}>
    <App />
</Provider>, document.getElementById('root'));