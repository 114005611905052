export const costfactorsConstants = {
    
    GETCOSTFACTORS_REQUEST : 'GETCOSTFACTORS_REQUEST',
    GETCOSTFACTORS_SUCCESS : 'GETCOSTFACTORS_SUCCESS',
    GETCOSTFACTORS_FAILURE : 'GETCOSTFACTORS_FAILURE',

    UPDATESALESEMPLOYEES_REQUEST : 'UPDATESALESEMPLOYEES_REQUEST',
    UPDATESALESEMPLOYEES_SUCCESS : 'UPDATESALESEMPLOYEES_SUCCESS',
    UPDATESALESEMPLOYEES_FAILURE : 'UPDATESALESEMPLOYEES_FAILURE',

    ADDCOSTFACTORS_REQUEST : 'ADDCOSTFACTORS_REQUEST',
    ADDCOSTFACTORS_SUCCESS : 'ADDCOSTFACTORS_SUCCESS',
    ADDCOSTFACTORS_FAILURE : 'ADDCOSTFACTORS_FAILURE',

    EDITCOSTFACTORS_REQUEST : 'EDITCOSTFACTORS_REQUEST',
    EDITCOSTFACTORS_SUCCESS : 'EDITCOSTFACTORS_SUCCESS',
    EDITCOSTFACTORS_FAILURE : 'EDITCOSTFACTORS_FAILURE',


    TOGGLE_NEW_MODAL : 'TOGGLE_NEW_MODAL',
    TOGGLE_EDIT_MODAL : 'TOGGLE_EDIT_MODAL'
};
