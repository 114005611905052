import { addressConstants } from '../_constants';
import { addressService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const addressActions = {
    importAddressFile,
    createOneTimeLink,
    getAllAddresses,
    updateAddress,
};


function importAddressFile(data) {
    return dispatch => {
        dispatch(request());

        return addressService.importAddressFile(data)
            .then(
                importResult => {
                    if (importResult.errorMessages && importResult.errorMessages.length > 0) {
                        dispatch(failure(importResult.errorMessages));
                    } else {
                        dispatch(success(importResult))
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: addressConstants.IMPORT_ADDRESS_FILE_REQUEST } }
    function success(importResult) { return { type: addressConstants.IMPORT_ADDRESS_FILE_SUCCESS, importResult } }
    function failure(errorMessages) { return { type: addressConstants.IMPORT_ADDRESS_FILE_FAILURE, errorMessages: errorMessages } }
}

function createOneTimeLink() {
    return dispatch => {
        dispatch(request());

        return addressService.createOneTimeLink()
            .then(
                tokenResult => {
                    if (tokenResult.errorMessages && tokenResult.errorMessages.length > 0) {
                        dispatch(failure(tokenResult.errorMessages));
                    } else {
                        dispatch(success(tokenResult))
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: addressConstants.CREATE_ONE_TIME_LINK_REQUEST } }
    function success(tokenResult) { return { type: addressConstants.CREATE_ONE_TIME_LINK_SUCCESS, tokenResult } }
    function failure(errorMessages) { return { type: addressConstants.CREATE_ONE_TIME_LINK_FAILURE, errorMessages: errorMessages } }
}

function getAllAddresses(filter, page, limit) {
    return dispatch => {
        dispatch(request());

        return addressService.getAllAddresses(filter, page, limit)
            .then(
                result => {
                    if (result.errorMessage) {
                        dispatch(failure(result.errorMessage));
                    } else {
                        dispatch(success(result));
                        return result;
                    }
                },
                error => {
                    dispatch(failure(error));
                })
    };

    function request() { return { type: addressConstants.GET_ALL_ADDRESSES_REQUEST } }
    function success(result) { return { type: addressConstants.GET_ALL_ADDRESSES_SUCCESS, result: result } }
    function failure(error) { return { type: addressConstants.GET_ALL_ADDRESSES_FAILURE, errorMessage: error } }
}

function updateAddress(address) {
    return dispatch => {
        dispatch(request());

        return addressService.updateAddress(address)
            .then(
                result => {
                    if (result.errorMessage) {
                        dispatch(failure(result.errorMessage));
                    } else {
                        dispatch(success(result.response));
                        return result;
                    }
                },
                error => {
                    dispatch(failure(error));
                })
    };

    function request() { return { type: addressConstants.UPDATE_ADDRESS_REQUEST } }
    function success(result) { return { type: addressConstants.UPDATE_ADDRESS_SUCCESS, result: result } }
    function failure(error) { return { type: addressConstants.UPDATE_ADDRESS_FAILURE, errorMessage: error } }
}