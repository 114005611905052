import { authHeader } from '../_helpers';
import "isomorphic-fetch";
import { history } from '../_helpers';
import { handleResponse } from './responseHandler';

export const shopifyService = {
    getShopifyConfigurations,
    saveShopifyImportConfiguration,
    startShopifyImport,
    startShopifyAccountingImport,
    assignClientToConfiguration,
    deleteConfiguration,
    addShopifyIntegration,
    addShopifyPaymentsIntegration,
    getShopifyPaymentsIntegrations,
    deleteShopifyPaymentsIntegration
};


function getShopifyConfigurations() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/integrations/shopify', requestOptions).then(handleResponse);
}

function saveShopifyImportConfiguration(configuration) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(configuration)
    };

    return fetch(process.env.REACT_APP_API_BASE_URL+'/integrations/shopify', requestOptions).then(handleResponse);
}

function startShopifyImport(shopifyParameter) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(shopifyParameter)
    };

    return fetch(process.env.REACT_APP_API_BASE_URL+'/integrations/shopify/startimport', requestOptions).then(handleResponse);
}

function startShopifyAccountingImport(shopifyParameter) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            externalUUID : shopifyParameter.configurationExternalUUID,
            begin : shopifyParameter.begin,
            end : shopifyParameter.end
        })
    };

    return fetch(process.env.REACT_APP_API_BASE_URL+'/integrations/shopify-accounting-import', requestOptions).then(handleResponse);
}

function assignClientToConfiguration(configurationExternalUUID,clientId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({configurationExternalUUID: configurationExternalUUID, clientId : clientId})
    };

    return fetch(process.env.REACT_APP_API_BASE_URL+'/integrations/shopify/assignclient', requestOptions).then(handleResponse);
}

function deleteConfiguration(externalUUID) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(process.env.REACT_APP_API_BASE_URL+'/integrations/shopify/'+externalUUID, requestOptions).then(handleResponse);
}

function addShopifyIntegration(newIntegration) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(newIntegration)
    };

    return fetch(process.env.REACT_APP_API_BASE_URL+'/integrations/shopify/add', requestOptions).then(handleResponse);
}

function addShopifyPaymentsIntegration(newIntegration) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(newIntegration)
    };

    return fetch(process.env.REACT_APP_API_BASE_URL+'/integrations/shopifypayments', requestOptions).then(handleResponse);
}

function getShopifyPaymentsIntegrations() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/integrations/shopifypayments', requestOptions).then(handleResponse);
}

function deleteShopifyPaymentsIntegration(integrationId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(process.env.REACT_APP_API_BASE_URL+'/integrations/shopifypayments/'+integrationId, requestOptions).then(handleResponse);
}
