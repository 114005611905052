import { callcenterConstants } from '../_constants/callcenter.constants';


export function callCenter (state = {}, action) {
    switch (action.type) {


        case callcenterConstants.GET_CALLCENTEREMPLOYEES_REQUEST:
            return {
                ...state,
                loading: true,
                employees : []
            };
        case callcenterConstants.GET_CALLCENTEREMPLOYEES_SUCCESS:
            return {
                ...state,
                employees: action.callCenterEmployees,
                loading: false
            };
        case callcenterConstants.GET_CALLCENTEREMPLOYEES_FAILURE:
            return {
                ...state,
                error: action.error,
                employees : [],
                loading: false
            };

        case callcenterConstants.UPDATE_CALLCENTEREMPLOYEES_REQUEST:
            return {
                ...state,
                loading: true
            };
        case callcenterConstants.UPDATE_CALLCENTEREMPLOYEES_SUCCESS:
            return {
                ...state,
                employees: action.callCenterEmployees,
                loading: false
            };
        case callcenterConstants.UPDATE_CALLCENTEREMPLOYEES_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            };

        case callcenterConstants.TOGGLE_CALLCENTER_MODAL:
            return {
                ...state,
                modal : !state.modal,
                centers : action.callCenterItems
            };

        case callcenterConstants.GET_CALLCENTERITEMS_REQUEST:
            return {
                ...state,
                loading: true,
                centers : []
            };
        case callcenterConstants.GET_CALLCENTERITEMS_SUCCESS:
            return {
                ...state,
                centers: action.callCenterItems,
                loading: false
            };
        case callcenterConstants.GET_CALLCENTERITEMS_FAILURE:
            return {
                ...state,
                error: action.error,
                centers : [],
                loading: false
            };

        case callcenterConstants.UPDATE_CALLCENTERITEMS_REQUEST:{
            return {
                ...state,
                loading: true
            }
        }

        case callcenterConstants.UPDATE_CALLCENTERITEMS_SUCCESS:{
            return {
                ...state,
                centers : action.callCenterItems,
                modal : false,
                loading: false
            }
        }

        case callcenterConstants.UPDATE_CALLCENTERITEMS_FAILURE:{
            return {
                ...state,
                error: action.error,
                loading: false
            };
        }

        case callcenterConstants.CLEAR_ERROR_MESSAGES:{
            return {
                ...state,
                loading: false,
                errorMessages: [],
                reportMessage : null,
                fileName : null
            };
        }


        default:
            return state
    }
}