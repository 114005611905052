import {adminConstants, userConstants} from '../_constants';
import { navigationConstants } from '../_constants';

import { userService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const userActions = {
    login,
    logout,
    isUserLoggedIn,
    getUserAllowedModules
};

function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch({type:userConstants.ADD_ADDITIONAL_USER_DATA, 'user' : JSON.parse(localStorage.getItem('jtcareuser'))});
                    dispatch({type: navigationConstants.UPDATE_NAVIGATION_ITEMS, allowedModules: user.allowedModules});
                    history.push('/');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}


function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function isUserLoggedIn(content) {
    return dispatch => {
        dispatch(request());

        return userService.checkAuthentication(content)
            .then(
                user => {
                    dispatch(success());
                    return true;
                },
                error => {
                    dispatch(failure());
                    return false
                }
            );
    };

    function request(user) { return { type: userConstants.CHECK_AUTHENTICATION_REQUEST } }
    function success(user) { return { type: userConstants.CHECK_AUTHENTICATION_SUCCESS } }
    function failure(error) { return { type: userConstants.CHECK_AUTHENTICATION_FAILURE} }
}

function getUserAllowedModules() {
    return dispatch => {
        dispatch(request());

        return userService.getUserAllowedModules()
            .then(
                allowedModules => {
                    return dispatch(success(allowedModules));
                },
                error => {
                    dispatch(failure());
                }
            );
    };

    function request() { return { type: userConstants.GET_USER_ALLOWED_MODULES_REQUEST } }
    function success(allowedModules) { return { type: userConstants.GET_USER_ALLOWED_MODULES_SUCCESS, allowedModules } }
    function failure() { return { type: userConstants.GET_USER_ALLOWED_MODULES_FAILURE} }
}
