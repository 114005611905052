export const mailseriesConstants = {

    START_MAIL_SERIES_EXPORT_REQUEST : 'START_MAIL_SERIES_EXPORT_REQUEST',
    START_MAIL_SERIES_EXPORT_SUCCESS : 'START_MAIL_SERIES_EXPORT_SUCCESS',
    START_MAIL_SERIES_EXPORT_FAILURE : 'START_MAIL_SERIES_EXPORT_FAILURE',

    TOGGLE_EXPORT_OVERVIEW_MODAL : 'TOGGLE_EXPORT_OVERVIEW_MODAL',

    CLEAR_PROPERTIES : 'CLEAR_PROPERTIES'
};
