import { authHeader } from '../_helpers';
import "isomorphic-fetch";
import { history } from '../_helpers';
import { handleResponse } from './responseHandler';
import { userConstants } from '../_constants';

export const userService = {
    login,
    logout,
    getById,
    useRefreshToken,
    checkAuthentication,
    getUserAllowedModules
};

function login(email, password) {

    var details = {
        'username': email,
        'password': password,
        'grant_type': 'password'
    };

    var formBody = [];
    for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/oauth/token', {
        method: 'POST',
        headers: {
            'Authorization': 'Basic ' + btoa("pathwaysolutions"+':'+"d8a33bd84987c896955bd05afbc2918c2b3610472703b0288993a96a12c9f3fe"),
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: formBody
        })
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            return response.json();
        })
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user && user.access_token) {
                localStorage.setItem('jtcareuser', JSON.stringify(user));                
            }

            return user;
        })
        .then(initialUserData => {

            // retrieve additional user details
            const requestOptions = {
                method: 'GET',
                headers: authHeader()
            };

            return window.fetch(process.env.REACT_APP_API_BASE_URL+'/user', requestOptions).then(additionalUserData => {

                if (!additionalUserData.ok) {
                    return Promise.reject(additionalUserData.statusText);
                }

                return additionalUserData.json().then((jsonData) =>{
                    let user = JSON.parse(localStorage.getItem('jtcareuser'));

                    for(var key in jsonData) {user[key] = jsonData[key];}
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('jtcareuser', JSON.stringify(user));
                    return user;
                });
            });
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('jtcareuser');
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return window.fetch('/users/' + id, requestOptions).then(handleResponse);
}

function useRefreshToken() {

    let user = JSON.parse(localStorage.getItem('jtcareuser'));

    var details = {
        'refresh_token': user.refresh_token,
        'grant_type': 'refresh_token'
        };

    var formBody = [];
    for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/oauth/token', {
        method: 'POST',
        headers: {
            'Authorization': 'Basic ' + btoa("pathwaysolutions"+':'+"secretforbasicauth"),
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: formBody
        })
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            return response.json();
        })
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user && user.access_token) {
                localStorage.setItem('jtcareuser', JSON.stringify(user));                
            }
            return user;
        })
        .then(initialUserData => {

            // retrieve additional user details
            const requestOptions = {
                method: 'GET',
                headers: authHeader()
            };

            return window.fetch(process.env.REACT_APP_API_BASE_URL+'/user', requestOptions).then(additionalUserData => {

                if (!additionalUserData.ok) {
                    return Promise.reject(additionalUserData.statusText);
                }

                return additionalUserData.json().then((jsonData) =>{
                    let user = JSON.parse(localStorage.getItem('jtcareuser'));

                    for(var key in jsonData) {user[key] = jsonData[key];}
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('jtcareuser', JSON.stringify(user));
                    return user;
                });
            });
        });
}

function checkAuthentication(content) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/jtcare/authcheck', requestOptions).then(handleResponse);
}

function getUserAllowedModules() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/jtcare/user/allowedmodules', requestOptions).then(handleResponse);
}

