import { callcenterConstants } from '../_constants';
import { callcenterService } from '../_services';
import { alertActions } from './';

export const callCenterActions = {
    getAllEmployees,
    updateEmployees,
    toggleCallCenterModal,
    updateCallCenterItems,
    getAllCallCenterItems
};

function getAllEmployees() {
    return dispatch => {
        dispatch(request());

        return callcenterService.getAllEmployees()
            .then(
                callCenterEmployees => {
                    dispatch(success(callCenterEmployees));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: callcenterConstants.GET_CALLCENTEREMPLOYEES_REQUEST } }
    function success(callCenterEmployees) { return { type: callcenterConstants.GET_CALLCENTEREMPLOYEES_SUCCESS, callCenterEmployees } }
    function failure(error) { return { type: callcenterConstants.GET_CALLCENTEREMPLOYEES_FAILURE, error } }
}


function updateEmployees(callCenterEmployees) {
    return dispatch => {
        dispatch(request(callCenterEmployees));

        return callcenterService.updateCallCenterEmployees(callCenterEmployees)
            .then(
                callCenterEmployees => {
                    if (callCenterEmployees.errorMessages && callCenterEmployees.errorMessages.length > 0) {
                        dispatch(failure(callCenterEmployees.errorMessages));
                    } else {
                        dispatch(success(callCenterEmployees))
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(callCenterEmployees) { return { type: callcenterConstants.UPDATE_CALLCENTEREMPLOYEES_REQUEST, callCenterEmployees } }
    function success(callCenterEmployees) { return { type: callcenterConstants.UPDATE_CALLCENTEREMPLOYEES_SUCCESS, callCenterEmployees } }
    function failure(errorMessages) { return { type: callcenterConstants.UPDATE_CALLCENTEREMPLOYEES_FAILURE, messages: errorMessages } }
}

function toggleCallCenterModal(openModal) {
    return dispatch => {
        if(openModal){
            return callcenterService.getAllCallCenterItems()
                .then(

                    callCenterItems => {
                            dispatch({type : callcenterConstants.TOGGLE_CALLCENTER_MODAL, callCenterItems});
                    },
                    error => {
                        dispatch(alertActions.error(error));
                    }
                );
        } else {
            dispatch({type : callcenterConstants.TOGGLE_CALLCENTER_MODAL});
            return Promise.resolve();
        }
    }
}

function getAllCallCenterItems() {
    return dispatch => {
        dispatch(request());


        return callcenterService.getAllCallCenterItems()
            .then(
                callCenterItems => {
                    dispatch(success(callCenterItems));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: callcenterConstants.GET_CALLCENTERITEMS_REQUEST  } }
    function success(callCenterItems) { return { type: callcenterConstants.GET_CALLCENTERITEMS_SUCCESS, callCenterItems } }
    function failure(errorMessages) { return { type: callcenterConstants.GET_CALLCENTERITEMS_FAILURE, messages: errorMessages } }
}

function updateCallCenterItems(callCenterItems) {
    return dispatch => {
        dispatch(request(callCenterItems));

        return callcenterService.updateCallCenterItems(callCenterItems)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages));
                    } else {
                        dispatch(success(callCenterItems))
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(callCenterItems) { return { type: callcenterConstants.UPDATE_CALLCENTERITEMS_REQUEST, callCenterItems } }
    function success(callCenterItems) { return { type: callcenterConstants.UPDATE_CALLCENTERITEMS_SUCCESS, callCenterItems } }
    function failure(errorMessages) { return { type: callcenterConstants.UPDATE_CALLCENTERITEMS_FAILURE, messages: errorMessages } }
}
