export const adminConstants = {
    GET_USERS_REQUEST : 'GET_USERS_REQUEST',
    GET_USERS_SUCCESS : 'GET_USERS_SUCCESS',
    GET_USERS_FAILURE : 'GET_USERS_FAILURE',

    GET_ALLOWED_MODULES_REQUEST : 'GET_ALLOWED_MODULES_REQUEST',
    GET_ALLOWED_MODULES_SUCCESS : 'GET_ALLOWED_MODULES_SUCCESS',
    GET_ALLOWED_MODULES_FAILURE : 'GET_ALLOWED_MODULES_FAILURE',

    UPDATE_USER_ALLOWED_MODULES_REQUEST : 'UPDATE_USER_ALLOWED_MODULES_REQUEST',
    UPDATE_USER_ALLOWED_MODULES_SUCCESS : 'UPDATE_USER_ALLOWED_MODULES_SUCCESS',
    UPDATE_USER_ALLOWED_MODULES_FAILURE : 'UPDATE_USER_ALLOWED_MODULES_FAILURE',

    TOGGLE_ALLOWED_MODULES_MODAL : 'TOGGLE_ALLOWED_MODULES_MODAL'
};
