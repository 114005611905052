export const shopifyConstants = {
    
    GETSHOPIFYDATA_REQUEST : 'GETSHOPIFYDATA_REQUEST',
    GETSHOPIFYDATA_SUCCESS : 'GETSHOPIFYDATA_SUCCESS',
    GETSHOPIFYDATA_FAILURE : 'GETSHOPIFYDATA_FAILURE',

    GETSHOPIFYCONFIGURATIONS_REQUEST : 'GETSHOPIFYCONFIGURATIONS_REQUEST',
    GETSHOPIFYCONFIGURATIONS_SUCCESS : 'GETSHOPIFYCONFIGURATIONS_SUCCESS',
    GETSHOPIFYCONFIGURATIONS_FAILURE : 'GETSHOPIFYCONFIGURATIONS_FAILURE',
    
    BEGIN_SHOPIFY_IMPORT : 'BEGIN_SHOPIFY_IMPORT',
    
    SAVESHOPIFYIMPORTCONFIGURATION_REQUEST : 'SAVESHOPIFYIMPORTCONFIGURATION_REQUEST',
    SAVESHOPIFYIMPORTCONFIGURATION_SUCCESS : 'SAVESHOPIFYIMPORTCONFIGURATION_SUCCESS',
    SAVESHOPIFYIMPORTCONFIGURATION_FAILURE : 'SAVESHOPIFYIMPORTCONFIGURATION_FAILURE',

    TOGGLE_ASSOCIATE_CLIENT_MODAL : 'TOGGLE_ASSOCIATE_CLIENT_MODAL',

    ASSIGN_CLIENT_TO_SHOPIFY_REQUEST : 'ASSIGN_CLIENT_TO_SHOPIFY_REQUEST',
    ASSIGN_CLIENT_TO_SHOPIFY_SUCCESS : 'ASSIGN_CLIENT_TO_SHOPIFY_SUCCESS',
    ASSIGN_CLIENT_TO_SHOPIFY_FAILURE : 'ASSIGN_CLIENT_TO_SHOPIFY_FAILURE',

    TOGGLE_DELETE_CONFIGURATION : 'TOGGLE_DELETE_CONFIGURATION',

    DELETE_SHOPIFY_CONFIGURATION_REQUEST : 'DELETE_SHOPIFY_CONFIGURATION_REQUEST',
    DELETE_SHOPIFY_CONFIGURATION_SUCCESS : 'DELETE_SHOPIFY_CONFIGURATION_SUCCESS',
    DELETE_SHOPIFY_CONFIGURATION_FAILURE : 'DELETE_SHOPIFY_CONFIGURATION_FAILURE',

    PERFORM_SHOPIFY_ACCOUNTING_IMPORT_REQUEST: 'PERFORM_SHOPIFY_ACCOUNTING_IMPORT_REQUEST',
    PERFORM_SHOPIFY_ACCOUNTING_IMPORT_SUCCESS: 'PERFORM_SHOPIFY_ACCOUNTING_IMPORT_SUCCESS',
    PERFORM_SHOPIFY_ACCOUNTING_IMPORT_FAILURE: 'PERFORM_SHOPIFY_ACCOUNTING_IMPORT_FAILURE',

    TOGGLE_NEW_CONFIGURATION: 'TOGGLE_NEW_CONFIGURATION',
    ADDSHOPIFYINTEGRATION_REQUEST : 'ADDSHOPIFYINTEGRATION_REQUEST',
    ADDSHOPIFYINTEGRATION_SUCCESS : 'ADDSHOPIFYINTEGRATION_SUCCESS',
    ADDSHOPIFYINTEGRATION_FAILURE : 'ADDSHOPIFYINTEGRATION_FAILURE',

    TOGGLE_ADDSHOPIFYPAYMENTSINTEGRATION : 'TOGGLE_ADDSHOPIFYPAYMENTSINTEGRATION',

    ADDSHOPIFYPAYMENTSINTEGRATION_REQUEST : 'ADDSHOPIFYPAYMENTSINTEGRATION_REQUEST',
    ADDSHOPIFYPAYMENTSINTEGRATION_SUCCESS : 'ADDSHOPIFYPAYMENTSINTEGRATION_SUCCESS',
    ADDSHOPIFYPAYMENTSINTEGRATION_FAILURE : 'ADDSHOPIFYPAYMENTSINTEGRATION_FAILURE',

    TOGGLE_DELETESHOPIFYPAYMENTSINTEGRATION : 'TOGGLE_DELETESHOPIFYPAYMENTSINTEGRATION',

    DELETESHOPIFYPAYMENTSINTEGRATION_SUCCESS : 'DELETESHOPIFYPAYMENTSINTEGRATION_SUCCESS',
    DELETESHOPIFYPAYMENTSINTEGRATION_FAILURE : 'DELETESHOPIFYPAYMENTSINTEGRATION_FAILURE',

    GETSHOPIFYPAYMENTSINTEGRATIONS_REQUEST : 'GETSHOPIFYPAYMENTSINTEGRATIONS_REQUEST',
    GETSHOPIFYPAYMENTSINTEGRATIONS_SUCCESS : 'GETSHOPIFYPAYMENTSINTEGRATIONS_SUCCESS',
    GETSHOPIFYPAYMENTSINTEGRATIONS_FAILURE : 'GETSHOPIFYPAYMENTSINTEGRATIONS_FAILURE',

};
